import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

const memes = [
  {
    name: "Try Amex",
    url: "/card"
  }
];

export default function Start() {
  return (
    <Layout>
      <SEO title="MEMES" />

      <div className="pad-10 rainbow-background ">
        <a href="https://sld.codes" className="is-white link grow">
          <h2 className="  margin-2-b grow">{`< Home`}</h2>
        </a>
        <h1 className="is-white is-hero-menu animated shakeit">MEMES</h1>
        <h2>👋 Hello beautiful people, have some memes.</h2>

        <div className="row ">
          {memes.map(item => (
            <div className="col-xs-12 col-md-6 col-lg-4 margin-5-t">
              <Link to={item.url} className="link">
                <div className="btn">{item.name}</div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
}
